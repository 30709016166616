import { END_DATE, MonthType, START_DATE, useDatepicker } from '@datepicker-react/hooks';
import { OnDatesChangeProps, UseDatepickerProps } from '@datepicker-react/hooks/lib/useDatepicker/useDatepicker';
import { parseDate } from 'common/utils/date';
import { useAtom } from 'jotai';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastContext } from 'TopContexts';
import { searchFormDatesAtom } from 'atoms/searchFormAtoms';
import { ToastType } from 'components/common/Toast/Toast.types';
import { LayoutContext } from 'components/contexts/LayoutContext';
import { OffscreenContext } from 'components/contexts/OffscreenContext';
import CustomDatepickerStyle from 'components/datePicker/customDatepicker/CustomDatepicker.styles';
import { DatepickerContext } from 'components/datePicker/customDatepicker/DatepickerContext';
import { DatepickerWidgetButton } from 'components/datePicker/customDatepicker/DatepickerWidgetButton';
import { DesktopDatepickerLayout } from 'components/datePicker/customDatepicker/DesktopDatepickerLayout';
import { MobileDatepickerLayout } from 'components/datePicker/customDatepicker/MobileDatepickerLayout';
import OffscreenMode from 'components/mobile/offscreen/OffscreenMode';
import { env } from 'environments/environment';
import {
  areDatesEqual,
  calculateNightsBetweenDates,
  getPeriodOverMaxNightsMessage,
  isPeriodOverMaxNights,
  shiftDays,
  stringifyDate,
} from 'utils/dateUtils';

const MOBILE_MONTHS = 16;
const DESKTOP_MONTHS = 2;
const MIN_BOOKING_DAYS = 1;

interface CustomDatepickerProps {
  showOnlyWidget?: boolean;
}

export const CustomDatepicker = ({ showOnlyWidget }: CustomDatepickerProps) => {
  const { t } = useTranslation();
  const { isMobileLayout } = useContext(LayoutContext);
  const { offscreenMode, setOffscreenMode, goToPreviousOffscreenMode } = useContext(OffscreenContext);
  const setToast = useContext(ToastContext);

  const [{ checkin, checkout }, setDates] = useAtom(searchFormDatesAtom);
  const parsedStart = useMemo<Date | null>(() => parseDate(checkin) || null, [checkin]);
  const parsedEnd = useMemo<Date | null>(() => parseDate(checkout) || null, [checkout]);

  const [displayedMonths, setDisplayedMonths] = useState<MonthType[]>([]);
  const [updateDisplayedMonths, setUpdateDisplayedMonths] = useState(false);

  const [state, setState] = useState<OnDatesChangeProps>({
    startDate: parsedStart,
    endDate: parsedEnd,
    focusedInput: null,
  });

  useEffect(() => {
    setState((prev) => ({
      startDate: parsedStart,
      endDate: parsedEnd,
      focusedInput: prev.focusedInput,
    }));
  }, [parsedStart, parsedEnd]);

  const isOpened = useMemo(() => !!state.focusedInput, [state.focusedInput]);
  const isMobileExpanded = useMemo(
    () =>
      isMobileLayout &&
      (offscreenMode === OffscreenMode.searchDatepicker || offscreenMode === OffscreenMode.datepicker),
    [isMobileLayout, offscreenMode],
  );

  const widgetRef = useRef<HTMLDivElement>(null);

  const outsideClickCallback = useCallback(() => {
    setState({
      startDate: parsedStart,
      endDate: parsedEnd,
      focusedInput: null,
    });
    if (widgetRef.current) {
      widgetRef.current.blur();
    }

    if (isPeriodOverMaxNights(parsedStart, parsedEnd)) {
      setToast(getPeriodOverMaxNightsMessage(t), ToastType.Error);
    }
  }, [parsedStart, parsedEnd, setToast, t]);

  const fixData = useCallback(
    (data: OnDatesChangeProps): OnDatesChangeProps => {
      if (data.focusedInput === END_DATE) {
        return { ...data, endDate: null };
      }

      if (data.startDate && data.endDate && areDatesEqual(data.startDate, data.endDate)) {
        return { ...data, endDate: null, focusedInput: END_DATE };
      }

      if (data.focusedInput === null && isMobileLayout) {
        return { ...data, focusedInput: START_DATE };
      }

      return data;
    },
    [isMobileLayout],
  );

  const assignChangedDate = useCallback(
    (data: OnDatesChangeProps) => {
      setState(data);

      if (data.startDate && data.endDate && !isMobileLayout) {
        if (data.focusedInput === null && isPeriodOverMaxNights(data.startDate, data.endDate)) {
          setToast(getPeriodOverMaxNightsMessage(t), ToastType.Error);
        }
        setDates({ checkin: stringifyDate(data.startDate), checkout: stringifyDate(data.endDate) });
      }
    },
    [isMobileLayout, setDates, setToast, t],
  );

  const onDatesChange = useCallback(
    (data: OnDatesChangeProps) => {
      const fixedData = fixData(data);

      assignChangedDate(fixedData);
    },
    [assignChangedDate, fixData],
  );

  const objectForDatepicker = useMemo<UseDatepickerProps>(
    () => ({
      ...state,
      onDatesChange,
      minBookingDate: new Date(),
      numberOfMonths: isMobileLayout ? MOBILE_MONTHS : DESKTOP_MONTHS,
      minBookingDays: MIN_BOOKING_DAYS,
      initialVisibleMonth: (!isMobileLayout && parsedStart) || new Date(),
      maxBookingDate:
        state.focusedInput === START_DATE
          ? shiftDays(new Date(), env.searchBar.searchDateRange - 1)
          : shiftDays(new Date(), env.searchBar.searchDateRange),
    }),
    [state, onDatesChange, isMobileLayout, parsedStart],
  );

  const {
    firstDayOfWeek,
    activeMonths,
    goToDate,
    isDateSelected,
    isDateHovered,
    isFirstOrLastSelectedDate,
    isDateBlocked,
    isDateFocused,
    focusedDate,
    onDateHover,
    hoveredDate,
    onDateSelect,
    onDateFocus,
    goToPreviousMonthsByOneMonth,
    goToNextMonthsByOneMonth,
  } = useDatepicker(objectForDatepicker);

  useEffect(() => {
    if (updateDisplayedMonths) {
      setDisplayedMonths(activeMonths);
      setUpdateDisplayedMonths(false);
    }
  }, [activeMonths, updateDisplayedMonths, goToDate, goToPreviousMonthsByOneMonth, isMobileLayout, parsedStart]);

  const nights = useMemo(() => {
    if (isMobileLayout) {
      return calculateNightsBetweenDates(state.startDate, state.endDate);
    }

    return calculateNightsBetweenDates(
      state.startDate,
      state.focusedInput === END_DATE ? hoveredDate || state.endDate : state.endDate,
    );
  }, [isMobileLayout, state.startDate, state.focusedInput, state.endDate, hoveredDate]);

  const confirmOk = useCallback(() => {
    if (!state.startDate || !state.endDate) {
      return;
    }

    if (isPeriodOverMaxNights(state.startDate, state.endDate)) {
      setToast(getPeriodOverMaxNightsMessage(t), ToastType.Error);

      return;
    }

    setDates({ checkin: stringifyDate(state.startDate), checkout: stringifyDate(state.endDate) });
    goToPreviousOffscreenMode();
  }, [state.startDate, state.endDate, setDates, goToPreviousOffscreenMode, setToast, t]);

  const [scrollFlag, setScrollFlag] = useState<boolean>(false);
  const scrollRef = useRef<HTMLDivElement>(null);
  const [startMonthRef, setStartMonthRef] = useState<HTMLDivElement>();

  const setCurrentDate = useCallback(() => {
    goToDate(isMobileLayout ? new Date() : parsedStart || new Date());
    setUpdateDisplayedMonths(true);
    setState({
      startDate: parsedStart,
      endDate: parsedEnd,
      focusedInput: START_DATE,
    });
  }, [goToDate, isMobileLayout, parsedStart, parsedEnd]);

  useEffect(() => {
    if (isMobileExpanded) {
      setCurrentDate();

      const currentDate = new Date();

      if (
        parsedStart &&
        (parsedStart.getFullYear() !== currentDate.getFullYear() || parsedStart.getMonth() !== currentDate.getMonth())
      ) {
        setScrollFlag(true);
      }
    }
  }, [isMobileExpanded, parsedEnd, parsedStart, setCurrentDate]);

  useEffect(() => {
    if (scrollFlag && startMonthRef && scrollRef.current) {
      const parentRect = scrollRef.current.getBoundingClientRect();
      const childRect = startMonthRef.getBoundingClientRect();

      scrollRef.current.scrollTop = childRect.top + scrollRef.current.scrollTop - parentRect.top;

      setScrollFlag(false);
    }
  }, [scrollFlag, startMonthRef]);

  const widgetButtonClick: React.EventHandler<React.SyntheticEvent> = useCallback(
    (e) => {
      e.preventDefault();

      if (isOpened) {
        return;
      }

      if (isMobileLayout) {
        if (offscreenMode === OffscreenMode.hidden) {
          setOffscreenMode(OffscreenMode.datepicker);
        } else {
          setOffscreenMode(OffscreenMode.searchDatepicker);
        }
      } else {
        setCurrentDate();
      }
    },
    [isOpened, isMobileLayout, offscreenMode, setOffscreenMode, setCurrentDate],
  );

  const prevNavButtonClick = useCallback(() => {
    setUpdateDisplayedMonths(true);
    goToPreviousMonthsByOneMonth();
  }, [goToPreviousMonthsByOneMonth]);

  const nextNavButtonClick = useCallback(() => {
    setUpdateDisplayedMonths(true);
    goToNextMonthsByOneMonth();
  }, [goToNextMonthsByOneMonth]);

  const isStartDateSelected = useCallback((date: Date) => areDatesEqual(state.startDate, date), [state.startDate]);
  const isEndDateSelected = useCallback((date: Date) => areDatesEqual(state.endDate, date), [state.endDate]);
  const isStartMonthSelected = useCallback(
    (year: number, month: number) =>
      (state.startDate && state.startDate.getFullYear() === year && state.startDate.getMonth() === month) || false,
    [state.startDate],
  );

  return (
    <CustomDatepickerStyle.DatepickerContainer>
      <DatepickerContext.Provider
        value={{
          focusedDate,
          isDateFocused,
          isDateSelected,
          isDateHovered,
          isDateBlocked,
          isFirstOrLastSelectedDate,
          isStartDateSelected,
          isEndDateSelected,
          isStartMonthSelected,
          onDateSelect,
          onDateFocus,
          onDateHover,
        }}
      >
        <DatepickerWidgetButton
          startDate={state.startDate}
          endDate={state.endDate}
          onClick={widgetButtonClick}
          widgetRef={widgetRef}
          offscreenMode={isMobileLayout && isMobileExpanded}
        />
        {isMobileExpanded && !showOnlyWidget && (
          <MobileDatepickerLayout
            nights={nights}
            displayedMonths={displayedMonths}
            firstDayOfWeek={firstDayOfWeek}
            setStartMonthRef={setStartMonthRef}
            isDatepickerSubmitDisabled={!state.endDate || !state.startDate}
            confirmOk={confirmOk}
            scrollRef={scrollRef}
          />
        )}
        {!isMobileLayout && isOpened && (
          <DesktopDatepickerLayout
            nights={nights}
            displayedMonths={displayedMonths}
            firstDayOfWeek={firstDayOfWeek}
            setStartMonthRef={setStartMonthRef}
            prevNavButtonClick={prevNavButtonClick}
            nextNavButtonClick={nextNavButtonClick}
            outsideClickCallback={outsideClickCallback}
          />
        )}
      </DatepickerContext.Provider>
    </CustomDatepickerStyle.DatepickerContainer>
  );
};
