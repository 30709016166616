import { parseDate } from 'common/utils/date';
import { useAtomValue } from 'jotai';

import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastContext } from 'TopContexts';
import { useSearchStateCallbacks } from 'atoms/hooks/useSearchStateCallbacks';
import {
  searchFormAutoPlaceAtom,
  searchFormCheckinAtom,
  searchFormCheckoutAtom,
  searchFormDestinationTermAtom,
  searchFormOccupancyAtom,
  searchFormPlaceAtom,
} from 'atoms/searchFormAtoms';
import { ToastType } from 'components/common/Toast/Toast.types';
import { env } from 'environments/environment';
import { ClientError, ClientErrorCode } from 'errors/clientError';
import { processError } from 'errors/errorUtils';
import { getPeriodOverMaxNightsMessage, isPeriodOverMaxNights } from 'utils/dateUtils';

interface SearchFormCallbacks {
  submitForm: () => void;
}

export const useSearchFormCallbacks = (): SearchFormCallbacks => {
  const [t] = useTranslation();
  const { submit } = useSearchStateCallbacks();
  const setToast = useContext(ToastContext);
  const place = useAtomValue(searchFormPlaceAtom);
  const autoPlace = useAtomValue(searchFormAutoPlaceAtom);
  const destinationTerm = useAtomValue(searchFormDestinationTermAtom);
  const occupancy = useAtomValue(searchFormOccupancyAtom);
  const checkin = useAtomValue(searchFormCheckinAtom);
  const checkout = useAtomValue(searchFormCheckoutAtom);

  const submitForm = useCallback(() => {
    const currentPlace = place || autoPlace;

    if (!currentPlace) {
      const term = destinationTerm.trim();

      if (term.length === 0) {
        setToast(t('index.search.not-found', 'Please enter destination!'), ToastType.Warn);
      } else if (term.length < env.searchBar.minimalTermLength) {
        setToast(t('search-bar.destination-too-short', 'Please type more characters'), ToastType.Warn);
      } else {
        const message = t(
          'errors.hotels.destination-missing',
          'Destination not supported. We keep adding new destinations every day!',
        );
        const error = {
          clientCodes: [ClientErrorCode.UnsupportedDestination],
          action: () => setToast(message, ToastType.Error),
        };

        processError(new ClientError(ClientErrorCode.UnsupportedDestination, [message]), {
          known: [error],
          default: error,
        });
      }

      return;
    }

    if (isPeriodOverMaxNights(parseDate(checkin), parseDate(checkout))) {
      setToast(getPeriodOverMaxNightsMessage(t), ToastType.Error);

      return;
    }

    submit(currentPlace, undefined, checkin, checkout, occupancy);
  }, [place, autoPlace, submit, checkin, checkout, occupancy, destinationTerm, setToast, t]);

  return {
    submitForm,
  };
};
