import useOnRouteScrollingRestore from 'common/hooks/useOnRouteScrollingRestore';
import React, { useContext, useEffect } from 'react';
import { DebugContext, MetaContext } from 'TopContexts';
import { useCampaign } from 'atoms/hooks/useCampaign';
import { useSession } from 'atoms/hooks/useSession';
import { useUserLocation } from 'atoms/hooks/useUserLocation';
import { useWatchSearchForm } from 'atoms/hooks/useWatchSearchForm';
import { useWatchSearchState } from 'atoms/hooks/useWatchSearchState';
import { hotjarUser } from 'backend/hotjarTags';
import { DEBUG_MODE_KEY } from 'backend/localStorageKeys';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import { LayoutContext } from 'components/contexts/LayoutContext';
import { OAuthProvider } from 'components/contexts/OAuthContext';
import { OffscreenProvider } from 'components/contexts/OffscreenProvider';
import { DesktopPageLayout } from 'components/layouts/desktop/DesktopPageLayout';
import { MobilePageLayout } from 'components/layouts/mobile/MobilePageLayout';
import { LogInfoMemoryStorage } from 'utils/storage/memory/LogInfoMemoryStorage';
import useLocalStorage from 'utils/useLocalStorage';
import { useClearRokt } from 'utils/useRokt';

const AppTop: React.FC = () => {
  const [isDebugMode, setDebugMode] = useLocalStorage(DEBUG_MODE_KEY, false);
  const { isMobileLayout } = useContext(LayoutContext);
  const { isGoogleCrawler } = useContext(MetaContext);
  const { data } = useUserLocation();
  const { session } = useSession();
  const { loading } = useCampaign();

  useWatchSearchState();
  useWatchSearchForm();

  useEffect(() => {
    const userId = session.principal?.hasUser()?.id;

    LogInfoMemoryStorage.userId = userId;

    if (session.initialized) {
      hotjarUser(userId ? userId.toString(10) : null);
    }
  }, [session.initialized, session.principal]);

  useEffect(() => {
    LogInfoMemoryStorage.clientLocation = data;
  }, [data]);

  useClearRokt();
  useOnRouteScrollingRestore();

  if (!isGoogleCrawler && (!session.initialized || loading)) {
    return <LoadingScreen fullscreen />;
  }

  return (
    <DebugContext.Provider value={{ isDebugMode, setDebugMode }}>
      <OAuthProvider>
        <OffscreenProvider>{isMobileLayout ? <MobilePageLayout /> : <DesktopPageLayout />}</OffscreenProvider>
      </OAuthProvider>
    </DebugContext.Provider>
  );
};

export default AppTop;
